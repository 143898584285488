import React from 'react'
import { useAuthenticated } from 'react-admin'

const CertificatesPage = () => {
  useAuthenticated()

  return (
    <>
    </>
  )
}

export default CertificatesPage
