import React, {useState} from 'react'
import {useAuthenticated} from 'react-admin'
import { PageTitle } from 'common'
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import request from "../providers/request";
import Alert from "../components/Alert";

const useStyles = makeStyles({
  card: {
    padding: 25
  },

  headerTitle: {
    fontSize: '2.125rem'
  },

  headerLink: {
    marginBottom: 25
  },

  mt4: {
    marginTop: 10
  },

  mt5: {
    marginTop: 25
  },

  subList: {
    '& li': {
      marginTop: 10,
      marginBottom: 10
    }
  },

  subListToggle: {
    '& li': {
      display: 'none',
      marginTop: 10,
      marginBottom: 10
    }
  },

  link: {
    color: '#c62828',
    fontWeight: "normal"
  },

  button: {
    backgroundColor: '#c62828',
    color: '#fff',
    border: "none",
    padding: 15,
    borderRadius: 4,
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: "normal",

    '&:hover': {
      backgroundColor: '#841a1a',
      cursor: 'pointer'
    },

    '&[disabled]': {
      backgroundColor: '#716f6f',
    },

    '&[disabled]:hover': {
      cursor: 'not-allowed'
    }
  },
});

const DemoPage = () => {
  useAuthenticated()

  const classes = useStyles();
  const [sending, setSending] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const createExam = (event) => {
    setSending(true)
    request.send('POST', '/api/exam/demo/create-case-exam', {})
      .then(response => {
        request.setToken(response.data.token)
        request.send('POST', 'https://demo.examen.eak-rus.ru:8443/api/exam/external/create-demo-case', {
          moduleId: 8
        })
          .then((response) => {
            setSending(false)
            setSuccessMessage('Заявка на демо-экзамен 2 этапа успешно создана. Пожалуйста проверьте письмо на вашей почте.')
          })
          .catch(error => {
            setSending(false)
            if (error.message.data) {
              setErrorMessage('Вы уже отправляли заявку.')
            } else {
              setErrorMessage('Возникли ошибки при создании пробного тестирования.')
            }
          })
      })
      .catch(error => {
        setSending(false)
        setErrorMessage('Возникли ошибки при создании пробного тестирования.')
      })
  }

  return (
    <>
      <PageTitle className={classes.headerTitle} title="Программный тренажер «Демо-экзамен 2 этапа»" />
      <Card className={classes.card}>
        <p>
          Тренажер предназначен для ознакомления претендентов с программным комплексом, который используется на 2 этапе квалификационного экзамена.
        </p>
        <p>
          С помощью тренажера претендент может заранее освоить функционал программы, увидеть, как представлены экзаменационные вопросы, потренироваться вносить ответы на примере модуля АУДИТОРСКАЯ ДЕЯТЕЛЬНОСТЬ И ПРОФЕССИОНАЛЬНЫЕ ЦЕННОСТИ.
        </p>
        <p>
          Воспользоваться программным тренажером можно в удобное вам время с любого устройства с доступом в интернет. Продолжительность демо-экзамена – 3 часа, но можно завершить экзамен досрочно по своему усмотрению.
        </p>
        <p>
          Внесенные Вами ответы проверяться/оцениваться не будут.
        </p>
        <p>
          Использование программного тренажера «Демо-экзамен 2 этапа» <b>бесплатно для всех претендентов</b>, чья регистрация для прохождения квалификационного экзамена подтверждена. Передача данных для «Демо-экзамена 2 этапа» посторонним лицам запрещена.
        </p>
        <p>
          После нажатия кнопки «Пройти демо-экзамен 2 этапа» Вам на почту, указанную при регистрации, придет ссылка и данные для входа в демо-экзамен. После окончания демо-экзамена данные для входа будут недействительны.
        </p>
        <p>
          Рекомендуем также ознакомиться с руководством по прохождению 2 этапа экзамена.
        </p>

        <p>
          <a className={classes.link} href="https://examen.eak-rus.ru:8443/training/Руководство_к_демо_экз_в_ЛК_претендента.docx" target="_blank">Скачать руководство 2 этап экзамена</a>
        </p>
        <p>
          <button className={classes.button} onClick={createExam} disabled={sending}>
            <b>Пройти демо-экзамен 2 этапа</b>
          </button>
        </p>

        {errorMessage && (
          <Alert
            type="error"
            message={errorMessage}
            handleClose={() => setErrorMessage('')}
          />
        )}

        {successMessage && (
          <Alert
            type="info"
            message={successMessage}
            handleClose={() => setErrorMessage('')}
          />
        )}

      </Card>
    </>
  )
}

export default DemoPage
